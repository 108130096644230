<template>
    <v-layout row id="panel" align-center justify-center>
        <v-flex align-center justify-center :style="{ overflow: 'scroll' , display: 'flex' }">
            <v-flex style="margin: 3% 15%;text-align: justify;">
                <div>1. Please make sure your internet connectivity is good, If you face any issue related to the poor internet connection, then the examination board is not responsible for this.</div>
                <br />
                <div>2. Please make sure no one near to you once you login to examination panel.</div>
                <br />
                <v-flex style="display:flex;">
                    <v-flex style="position: relative;">
                        <img style="width: 210px;" src="@/assets/webcam-toy-photo1_censored.jpg" />
                        <v-icon style="font-size: 45px;position: absolute;top: 0;left: 12px;" color='green'>verified_user</v-icon>
                    </v-flex>
                    <v-flex style="position: relative;">
                        <img style="width: 210px;" src="@/assets/webcam-toy-photo2_censored.jpg" />
                        <v-icon  style="font-size: 45px;position: absolute;top: 0;left: 12px;" color='error'>cancel</v-icon>
                    </v-flex>
                    <v-flex style="position: relative;">
                        <img style="width: 210px;" src="@/assets/webcam-toy-photo3_censored.jpg" />
                        <v-icon  style="font-size: 45px;position: absolute;top: 0;left: 12px;" color='error'>cancel</v-icon>
                    </v-flex>
                     <v-flex style="position: relative;">
                        <img style="width: 210px;" src="@/assets/webcam-toy-photo4_censored.jpg" />
                        <v-icon  style="font-size: 45px;position: absolute;top: 0;left: 12px;" color='error'>cancel</v-icon>
                    </v-flex>
                </v-flex>
                <div>3. Make Sure All your other software like browser, screen sharing software, etc. software should be closed once you login to this examination panel. If we found to switch the tab and you try to open any other software in b/w the examination So it comes under malpractice and It may your examination get locked and auto-submitted and not possible to resume again.</div>
                <br />
                <div>4. Please don't leave your place once you examination starts So if you want go to restroom then please do it before the start of the examination. During examination hours you are not allowed to go to the restroom.</div>
                <br />
                <div>5. Please make sure the Pen, pencil, rough sheet and other stationery items should be ready with you because during the examination you can not ask your guardian to bring this near you.</div>
                <br />
                <v-flex style="text-align: center;width: 500px;margin: auto;">
                    <h1>Demonstration Video </h1>
                    <video width="500" controls controlsList="nodownload">
                        <source :src="GetVideoURL" type="video/mp4">
                    </video>
                </v-flex>
                <v-flex xs6  style="margin:auto;">
                    <app-web-cam></app-web-cam>
                </v-flex>
                <v-flex mt-8 style="text-align: center !important;">
                    <v-checkbox v-model="checkbox" label='Check here to indicate that you have read and agree to the terms and condition of examination'></v-checkbox>
                    <v-btn :disabled='!Image || !webcam || !checkbox' @click="Accept" color='primary'>Agree & Continue</v-btn>
                </v-flex>
            </v-flex>
            <!-- <v-flex xs4 style="text-align: center;">
            </v-flex> -->
        </v-flex>
    </v-layout>
</template>
<script>
    import appWebCam from './Webcam'

    export default {
        components: { 
            appWebCam
        },
        data(){
          return {
              checkbox : false,
              webcam   : false,
              Image    : false
          }
        }, 
        created() {
            let _this = this;
            this.$eventHub.$on('WEBCAM_STATUS', this.SetStatus);
            this.$eventHub.$on('IMAGE_STATUS', this.SetImageStatus);
        },
        beforeDestroy() {
            this.$eventHub.$off('WEBCAM_STATUS');
            this.$eventHub.$off('IMAGE_STATUS');
        },
        computed: {
            height(){
                console.log()
                return (this.$store.getters.getHeight * 85) / 100
            },
            server(){
                return this.$store.getters.getServer
            },
            GetVideoURL(){
                return `${this.$store.getters.GetServer}/static/videos/examin_sample.mp4`
            },
            GetSkips(){
                return this.$store.getters.GetSkips
            },
            GetUpload(){
                return this.$store.getters.GetUpload
            },
        },
        methods: {
            Retry(){
                 Image = ""
            },
            Accept(){
                const element = document.documentElement; // Get the root element of the document
                if (element.requestFullscreen) {
                    element.requestFullscreen(); // Enter fullscreen mode
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen(); // Firefox
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen(); // Chrome, Safari, and Opera
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen(); // Internet Explorer and Edge
                }
                if(!this.GetUpload){

                    this.$router.push('/surround');
                }else{
                    this.$router.push('/login')
                }
            },
            SetStatus(data){
                this.webcam = data
            },
            SetImageStatus(data){
                this.Image = data
            }
        },
        mounted(){

            this.$eventHub.$emit("LOADING",'false')
            // this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Creating User","Color":"red","Close": false })
        }
    }
</script>