<template>
    <section>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <v-img alt="Examin" class="shrink mr-5 ml-5" contain :src="Logo" width="160" />
            </div>
            <v-spacer></v-spacer>
            <app-time-to-start v-if="$route.name === 'instruction'"></app-time-to-start>
            <app-exam-time v-if="$route.name === 'exam'"></app-exam-time>
            <v-icon v-if="connection">signal_wifi_4_bar</v-icon>
            <v-icon v-else>signal_wifi_off</v-icon>
            <v-app-bar-nav-icon v-if="$route.name === 'exam'" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>
        <router-view></router-view>
        <app-sidebar></app-sidebar>  
    </section>
</template>
<script>
import appServerTime from '@/components/ServerTime';
import appSidebar from '@/views/SideNavBar'
import appLogo from '@/assets/logo.png'
import appTimeToStart from '@/views/Time/TimeToStart.vue';
import appExamTime    from '@/views/Time/Time.vue';

export default {
    components: {
      appServerTime,
      appSidebar,
      appTimeToStart,
      appExamTime
    },
    computed: {
        Logo(){
            return appLogo
        },
        drawer: {
            get() {
                return this.$store.getters.GetDrawer;
            },
            set(value) {
                this.$store.commit('SetDrawer', value)
            }
        },
        connection(){
            return this.$store.getters.GetConnectionStatus;
        }
    }
}
</script>
<style scoped>

</style>