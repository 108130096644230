<template>
    <v-navigation-drawer
            v-model="drawer"
            permanant
            right
            app
            style="overflow-y: hidden !important;background-color: #fafafa" :width="300">
        <section v-if="camera">
            <app-cam></app-cam>
            <app-voice></app-voice>
        </section>
        <section style="text-align: center;" v-else>
            <img src="../assets/student.png" alt="" class="">
        </section>
        <app-student></app-student>
        <app-selected-subject></app-selected-subject>
        <app-buttons :items="questions"></app-buttons>
        <app-submit-btn></app-submit-btn>
    </v-navigation-drawer>
</template>
<script>
    import appButtons from './Buttons/Buttons.vue'
    import appStudent from './Student/Student.vue'
    import appSubmitBtn from './Buttons/Submit.vue'
    import appSelectedSubject from './subjects/SelectedSubject'
    import appCam from './ExamWebCam.vue'
    import appVoice from './VoiceVolume.vue'

    export default {
       components: {
         appButtons,
         appStudent,
         appSubmitBtn,
         appSelectedSubject,
         appCam,
         appVoice
       },
       computed: {
           drawer: {
               get() {
                   return this.$route.name === 'exam' ?  this.$store.getters.GetDrawer : false
               },
               set(value) {
                   this.$store.commit('SetDrawer', value)
               }
           },
           questions(){
               return this.$store.getters.getQuestionCount
           },
           camera(){
               return true
           }
       },
    }
</script>