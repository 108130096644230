import store from '../store'

import { AuthenticateLayout, DefaultLayout } from "@/components/layouts";
import NotFound     from '@/views/error/NotFound.vue';
import Deny         from '@/views/error/Deny.vue';
import Login        from '@/views/Login.vue';
import Surround     from '@/views/SurroundTest.vue';
import VerifyImage  from '@/views/VerifyImage.vue';
import Exam         from '@/views/exam/Exam.vue';
import Instruction  from '@/views/instruction/Instruction.vue'
import Result       from '@/views/result/Result.vue';
import ExamValidate from '@/views/ExamValidate.vue';
import System       from '@/views/SystemNo.vue'
import Final        from '@/views/Final.vue';

let Skips = store.getters.GetSkips

export const publicRoute = [
  {
    path: "/auth",
    component: AuthenticateLayout,
    meta: {
      title: "Auth",
      group: "apps",
      icon: "",
      auth_required : false
    },
    redirect: !Skips['Skip_System'] ? '/system' : !Skips['Skip_Verify'] ? '/verify' : '/login',
    children: [
      {
        path: "/system",
        name: "System",
        meta: {
          title: "System",
          auth_required : false
        },
        component: System
      },
      {
        path: "/verify",
        name: "Verify",
        meta: {
          title: "Verify",
          auth_required : false
        },
        component: VerifyImage
      },
      {
        path: "/surround",
        name: "Surround",
        meta: {
          title: "Surround",
          auth_required : false
        },
        component: Surround
      },
      {
        path: "/login",
        name: "Login",
        meta: {
          title: "Login",
          auth_required : false
        },
        component: Login
      },
      {
        path: "/exam-:uid",
        name: "exam panel",
        meta: {
          title: "exam panel",
          group: "apps",
          icon: "exam",
          auth_required : false
        },
        component: ExamValidate
      },
    ]
  },
  {
    path: "/",
    component: DefaultLayout,
    meta: {
      title: "exam",
      group: "apps",
      icon: "",
      auth_required : true
    },
    children: [
      {
        path: "/instruction",
        name: "instruction",
        meta: {
          title: "Instruction",
          group: "apps",
          icon: "exam",
          auth_required : true
        },
        component: Instruction
      },
      {
        path: "/exam",
        name: "exam",
        meta: {
          title: "exam",
          group: "apps",
          icon: "exam",
          auth_required : true
        },
        component: Exam
      },
      {
        path: "/result",
        name: "result",
        meta: {
          title: "result",
          group: "apps",
          icon: "exam",
          auth_required : true
        },
        component: Result
      },
      {
        path: "/final",
        name: "final",
        meta: {
          title: "final",
          group: "apps",
          icon: "exam",
          auth_required : true
        },
        component: Final
      }
    ]
  },
  {
    path: "*",
    component: NotFound,
    meta: {
      child : '',
      auth_required : false
    }
  }

]