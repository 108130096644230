<template>
    <v-layout row>
        <v-flex xs12 sm12 md12 style="height:89vh; overflow:scroll">
            <v-card>
                <v-flex style="text-align:center;padding-top:10px;">
                    <v-btn @click="FinalPage" color='primary'>Continue And Proceed</v-btn>
                </v-flex>
                <v-card-title v-if="display_results">
                    <h2>Predicted Total Marks / Result</h2>
                </v-card-title>
                <v-card-text v-if="display_results">
                    <v-flex style="display: flex; text-align: center;font-weight: 700;" >
                        <v-flex xs2 ma-2 pa-2 style="border: 1px solid lightgrey;background-color: rgb(2, 136, 209); color: white;">Predicted Total Marks *</v-flex>
                        <v-flex xs2 ma-2 pa-2 style="border: 1px solid lightgrey;color: black;">{{ result_to_be_displayed }} *</v-flex>
                        <v-flex xs3 pa-1 style="font-size: 10px;">
                            <span style="position: absolute;bottom: 0;left: 0;">* Predicted Marks May Vary From The Actual Result </span>
                        </v-flex>
                    </v-flex>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title style="padding-top:0px">
                    <h2 style=" text-align: center; padding: 8px;">Answered Report</h2>
                </v-card-title>
                <v-card-text v-for="(results,index) in finalResult" :key="index+'_Subject'" style="display:inline-block;width:100%;">
                    <h3 style=" text-align: center;margin-bottom: 15px;">{{ subjects[results[0].subject_id] }}</h3>
                    <div class="mainDiv">
                        <div class="options" style=" background-color: #0288d1;color: white;">
                            <div v-for="(title,index) in Answertitles" class="innerOption" :key="index+'_Title'">  {{ title }} </div>
                        </div>
                        <div class="options" v-for="(result,index) in results" :key="index+'_result'">
                            <div class="innerOption">{{ result.question_no }}</div>
                            <div class="innerOption">{{ result.response }}</div>   
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title>
                    <h2>Subject Wise Report</h2>
                </v-card-title>
                <v-card-text>
                    <table id="customers">
                        <thead>
                            <th v-for="(title,index) in titles" :key="index+'_Custo'">
                                {{ title }}
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(result,index) in subjectWiseResult" :key="index+'_subjectwise'">
                                <td>{{ subjects[result.subject_id] }}</td>
                                <td>{{ result.attempted_questions }}</td>
                                <td>{{ result.skipped_questions }}</td>
                                <td>{{ result.time }}</td>
                            </tr>
                        </tbody>
                    </table>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-title>
                    <h2>Total Report</h2>
                </v-card-title>
                <v-card-text>
                    <table id="customers">
                        <thead>
                        <th v-for="title in total_titles" :key="title">
                            {{ title }}
                        </th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ totalResult.attempted_questions }}</td>
                            <td>{{ totalResult.skipped_questions }}</td>
                            <td>{{ totalResult.overall_time }}</td>
                        </tr>
                        </tbody>
                    </table>
                </v-card-text>
                <v-flex style="text-align:center;">
                    <v-btn @click="FinalPage" color='primary'>Continue And Proceed</v-btn>
                </v-flex>
            </v-card>

        </v-flex>
    </v-layout>
</template>
<script>
    import Moment from 'moment';
    export default {
        data(){
          return {
              Answertitles: ['Question', 'Response'],
              titles: ['Subject', 'Attempted', 'Skipped', 'Time Taken(sec)'],
              total_titles: ['Attempted', 'Skipped', 'Time Taken(sec)'],
              display_results : false,
              result_to_be_displayed : 0
          }
        },
        computed: {
            height(){
                return (this.$store.getters.getHeight * 85) / 100
            },
            subjectWiseTable(){
                return this.$store.getters.getTotalSubjectsSavedResponse
            },
            finalResult(){
                return this.$store.getters.getTotalSavedResponse
            },
            subjectWiseResult(){
                return this.$store.getters.getSubjectWiseResult
            },
            totalResult(){
                return this.$store.getters.getTotalResult
            },
            subjects(){
                return this.$store.getters.getSubjecNames
            },
            GetDisplayedResult(){
                return this.$store.getters.GetDisplayedResult
            },
            GetUserInfo(){
                return this.$store.getters.GetUserInfo
            }
        },
        methods:{
            FinalPage(){
                this.$router.push('/final')
            },
            PredictResult(){

                let exam_date = Moment(this.GetUserInfo.datetime).format('YYYY-MM-DD')
                let exam_time = Moment(this.GetUserInfo.datetime).format('HH:MM')
                let exam_list = this.GetUserInfo.exam_list
                let current_exam = exam_list.find(x => {
                    if(x.start_date == exam_date && x.start_time == exam_time && x.exam_id == this.GetUserInfo.exam_id){
                        return x
                    }
                    return {}
                })
                if(current_exam.result_permission == 1 && current_exam.result_profile && current_exam.result_profile.length > 0){

                    this.display_results = true;
                    let ranges = current_exam.result_profile[0]['ranges'];
                    let total_obtained_marks = this.GetDisplayedResult.total_result.total_obtained_marks;
                    let correct_range = ranges.map(x => { let between_test = this.between(total_obtained_marks,x.l_range,x.f_range) ; 
                                                          return between_test ? x : {}}).filter(value => JSON.stringify(value) !== '{}');
                    let max_range = Math.max.apply(Math, ranges.map(function(o) { return o.f_range; }))
                    let max_correct_range = ranges.find(x => x.f_range == max_range );

                    if(correct_range && correct_range.length > 0 && total_obtained_marks > 0){

                        let final_range = correct_range[0];
                        this.result_to_be_displayed = total_obtained_marks - final_range.tolerance;

                    }else if(total_obtained_marks > 0){

                        this.result_to_be_displayed = total_obtained_marks - max_correct_range.tolerance;

                    }else{
                        
                        this.result_to_be_displayed = total_obtained_marks
                    }

                }else{

                    this.display_results = false;

                }
            },
            between(value,first,last) {

                let lower = Math.min(first,last) , upper = Math.max(first,last);
                return value >= lower && value <= upper ;

            }
        },
        mounted(){
            this.$eventHub.$emit("LOADING",'false');
            this.PredictResult()
        }
    }
</script>
<style scoped>
    .mainDiv{
        position: relative;
        width: 100%;
    }
    .options{
        width: 9%;
        text-align: center;
        margin: 0px;
        float: left;
        height: 80px;
        border: 1px solid rgba(128, 128, 128, 0.52);
        margin-bottom: 26px;
        border-bottom: 0px;
    }
    .innerOption{
        border-bottom: 1px solid rgba(128, 128, 128, 0.52);
        padding: 8px 0px;
        width: 100%;
        height: 50%;
    }
    #customers {
        border-collapse: collapse;
        width: 100%;
    }

    #customers td, #customers th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }

    #customers tr:nth-child(even){background-color: #f2f2f2;}

    #customers tr:hover {background-color: #ddd;}

    #customers th {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #0288D1;
        color: white;
    }
</style>