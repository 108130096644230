import Vue from "vue"
import Router from "vue-router"
import { publicRoute } from "./config"
import store  from '../store'

Vue.use(Router)

const router = new Router({
  mode: process.env.IS_ELECTRON ? "hash" : 'history',
  base: process.env.BASE_URL,
  routes : publicRoute
})

router.beforeEach((to, from, next) => {

    store.commit("SetLoading",true)
    let Token = store.getters.GetToken;
    console.log(Token)
    let Auth_Required = to.matched.some(record => record.meta.auth_required)
    Token && Auth_Required ? next() : Token && !Auth_Required ? next('/') : !Token && Auth_Required ? next('/auth') : next()
    
})


export default router