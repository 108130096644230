import store from '../store'
import QuestionResponse from './QuestionResponse'
import QuestionTime from './Time'
import DatastoreResponse from '../datastore/Response'
import SubjectWiseResult from '../datastore/SubjectWiseResult'
import TotalResult from '../datastore/TotalResult'
import Calculate from './Calculate';
import axios from 'axios';
import Promise from 'bluebird';
import Socket from '../socket/Socket'


export default {

    finish(manual){

        store.commit('StopClock')
        QuestionResponse.saveResponse()
        QuestionTime.saveTimeTaken()
        Calculate.calculate()

        return new Promise((resolve, reject) => {

            Promise.resolve(1)
                .then(response =>{

                    return !manual ? DatastoreResponse.store() : true
                })
                .then(response =>{

                    return this.SaveReponseToDB()
                })
                .then(response =>{

                    return this.calculateSubjectWiseResult()
                })
                .then(response =>{

                    store.commit('SetSubmit',{ type:"fetch_subject_wise" , 'status' : true })
                    let data = { "first_name" : store.getters.GetUserInfo['first_name'] , "user_id" : store.getters.GetUserInfo['id'] , 
                                "exam_id" : store.getters.GetUserInfo['exam_id'] , "datetime" :store.getters.GetUserInfo['datetime'] ,...response , "mock" : store.getters.GetMockExam}
                    return axios.post(`${store.getters.GetServer}/student/save_result`,data)
                })
                .then(response =>{

                    console.log(response['data'])
                    response && response['data'] && response['data']['response'] ? 
                                store.commit('SetSubmit',{ type:"update_subject_wise" , 'status' : true }) :
                                store.commit('SetSubmit',{ type:"update_subject_wise" , 'status' : false })
                    response && response['data'] && response['data']['response'] ? resolve(response['data']) : reject({"message":"ERR UR001"})
                })
                .catch(error =>{

                    store.commit('SetSubmit',{ type:"update_subject_wise" , 'status' : false })
                    reject(error);
                })
        })

    },
    calculateSubjectWiseResult(){

        return new Promise((resolve, reject) => {

            SubjectWiseResult.subject()
                .then(response =>{

                    return this.calculateTotalResult(response)
                })
                .then(response =>{
                    
                    resolve(response)
                })
                .catch(error =>{

                    store.commit('SetSubmit',{ type:"fetch_subject_wise" , 'status' : false })
                    reject(error)
                })
        })
    },
    calculateTotalResult(subjectWiseResult){

        return new Promise((resolve, reject) => {

            TotalResult.total(subjectWiseResult)
                .then(response => {

                    store.commit('setSubjectWiseResult', subjectWiseResult)
                    store.commit('setTotalResult', response)
                    resolve({"subjectwise_result" : subjectWiseResult , "total_result" : response })

                })
                .catch(error =>{

                    store.commit('SetSubmit',{ type:"fetch_subject_wise" , 'status' : false })
                    reject(error)
                })

        })
    },


    SaveReponseToDB(){

        return new Promise((resolve,reject) =>{

            DatastoreResponse.get()
                .then(response =>{

                    store.commit('setFinalQuestions')
                    store.commit('setFinalAnswers', response)
                    let filter_data = response.filter(x => x.updated == 0 )
                    let data = {
                        "responses" : filter_data,
                        "student"   : { "first_name" : store.getters.GetUserInfo['first_name'] },
                    }
                    store.commit('SetSubmit',{ type:"fetch_responses" , 'status' : true })
                    return axios.post(`${store.getters.GetServer}/student/save_responses`,data)

                })
                .then(response =>{

                    response && response['data'] && response['data']['response'] ? store.commit('SetSubmit',{ type:"update_responses" , 'status' : true }) : store.commit('SetSubmit',{ type : "update_responses" , status : false})
                    let responses = response && response['data'] && response['data']['response'] ? response['data']['response'] : []
                    let info = responses.map(x => { return { 'user_id' : x['user_id'] , "exam_id" : x['exam_id'] , "question_id" : x['question_id'] , "subject_id" : x['subject_id']}  })
                    return Promise.mapSeries(info,(query) => DatastoreResponse.update(query));

                })
                .then(response =>{

                    store.commit('SetSubmit',{ type:"update_responses" , 'status' : true })
                    resolve(true)
                })
                .catch(error =>{

                    store.commit('SetSubmit',{ type:"update_responses" , 'status' : false })
                    reject(error)
                })
        });
    },
}