<template>
    <section>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <v-img alt="Examin" class="shrink mr-5 ml-5" contain :src="Logo" width="160" />
            </div>
            <v-spacer></v-spacer>
            <v-flex style="text-align: right;margin: 0px 14px;">
                <app-server-time></app-server-time>
                <v-icon v-if="connection">signal_wifi_4_bar</v-icon>
                <v-icon v-else>signal_wifi_off</v-icon>
            </v-flex>
            </v-app-bar>
        <router-view></router-view>    
    </section>
</template>
<script>
import appServerTime from '@/components/ServerTime'
import appLogo from '@/assets/logo.png'
export default {
    components: {
      appServerTime
    },
    computed: {
        connection(){
            return this.$store.getters.GetConnectionStatus;
        },
        Logo(){
            return appLogo
        },
    }
}
</script>
<style scoped>

</style>