import Vue    from 'vue'
import axios  from "axios";
import App    from './App.vue'
import router from './router'
import store  from './store'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import io from 'socket.io-client'

import VueNedb from './datastore/index'
Vue.use(VueNedb);

import Socket  from './socket/Socket'

Vue.prototype.$Socket = Socket

Vue.config.productionTip = false

store.commit('SetToken',GetCookie('token'))

Vue.prototype.$eventHub = new Vue()

axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;

Vue.mixin({
  methods: {
      convertText(value){
          let txt = document.createElement("textarea");
          txt.innerHTML = value;
          return txt.value;
      }
  }
})

document.addEventListener("keydown",function(e){
  if (e.key === 'Escape' || e.key === 'F11'){
      return false;
  }
});

if(process.env.IS_ELECTRON){
  
  const { ipcRenderer } = require("electron")
  
  ipcRenderer.on("BLURED", function (event, data) {
    store.commit('SetBlured');
  });
  
  ipcRenderer.on("FOCUSED", function (event, data) {
    store.commit('SetFocused');
  });

  ipcRenderer.on("SYSTEM", function (event, data) {
    store.commit('SetSystemDetails', JSON.parse(data))
  });
}

axios.post(`${store.getters.GetServer}/admin/init-config`)
    .then(response =>{
      
        let resp_data = response && response['data'] && response['data']['response'] ? response['data']['response'] : {} ;
        CreateApp(resp_data , response.headers.date);

    })
    .catch(error =>{

        console.log(error)
        CreateApp({},new Date());

    })



function CreateApp(resp_data,server_date){

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    created(){  

          document.addEventListener("keydown",function(e){
            if (e.key === 'Escape' || e.key === 'F11'){
                    return false;
                }
          });  

          window.onfocus = function() {
            store.commit('SetFocused');
          };

          window.onblur = function() {
            store.commit('SetBlured');
          };

          resp_data && resp_data['Skip_Verify'] ? store.commit('SetVerify', resp_data['Skip_Verify']) : {}
          resp_data && resp_data['Login_Form'] ?  store.commit('SetLoginForm', resp_data['Login_Form']) :{}
          resp_data && resp_data['skip_upload'] ?  store.commit('SetUpload', resp_data['skip_upload']) :{}
          console.log(resp_data)
          // let server_time = new Date("2020-10-16T09:27:00Z").toISOString(undefined, {timeZone: 'Asia/Kolkata'})
          let server_time = new Date(server_date).toISOString(undefined, {timeZone: 'Asia/Kolkata'})
          store.commit('SetServerTime',server_time)
          console.log("Connected In Main " + server_time)
          Socket.close()
          let socket = io(`${this.$store.getters.GetServer}/student` , { query: `user_id=${Math.floor(100000 + Math.random() * 900000)}&first_name=${Math.floor(100000 + Math.random() * 900000)}_temp&system_no=${Math.floor(100 + Math.random() * 900)}` })
          Socket.init(socket);

      }
    }).$mount('#app');

}

function GetCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}