<template>
  <v-app>
    <v-main>
      <app-loading :dialog="Loading"></app-loading>
      <router-view></router-view>
      <app-snack-bar></app-snack-bar>
      <app-message></app-message>
      <app-lock-loader></app-lock-loader>
    </v-main>
  </v-app>
</template>

<script>
let servertimer = null;
import appLoading  from "@/components/Loader";
import appSnackBar from "@/components/Snackbar";
const publicIp = require('public-ip');
import Time  from './utils/Time';
import io from 'socket.io-client';
import Socket from './socket/Socket';
import Moment from 'moment';
import appMessage from "@/views/Message";
import appLockLoader from '@/views/TransparentLoader'

export default {
  components: {
      appLoading,
      appSnackBar,
      appMessage,
      appLockLoader
  },
  data(){
    return {
      
    }
  },
  created() {

    this.$eventHub.$on('LOADING', this.SetLoading);

  },
  beforeDestroy() {
      this.$eventHub.$off('LOADING');
  },
  computed: {
    Loading() {
      return this.$store.getters.GetLoading;
    },
    ServerTime(){
      return this.$store.getters.GetServerTime
    },
    GetMock(){
      return this.$store.getters.GetMockExam
    },
    GetUserInfo(){

      return { ...this.$store.getters.GetUserInfo }
    },
    GetFocusedCount(){

      return this.$store.getters.GetFocused
    }
  },
  watch:{
    ServerTime(){

      this.UpdateServerTimer()
    },
    GetMock(){
       if(this.GetMock){
         this.StartMockExam()
       }
    },
    GetFocusedCount(){
      if(this.GetFocusedCount > 5){

          let data = this.$store.getters.GetUserInfo
          data['id'] && data['firstname'] ? this.$Socket.LockUserScreen(data) : "";
          this.$eventHub.$emit("MESSAGE",{ dialog : true , Message : "Warning: Don't Switch to another application or software otherwise you examination got locked and submitted automatically."});

      }else if(this.GetFocusedCount <= 5){

          this.$eventHub.$emit("MESSAGE",{ dialog : true , Message : "Warning: Don't Switch to another application or software otherwise you examination got locked and submitted automatically."});

      }
    }
  },
  methods:{
    SetLoading(payload){

      this.$store.commit("SetLoading",payload == 'true' ? true : false)

    },
    UpdateServerTimer(){
      let time = new Date(this.ServerTime).getTime()
      time += 1000
      servertimer = setInterval(() => { this.$store.commit('SetUpdatedServerTime', time);time += 1000; }, 1000);
    },
    async GetIp(){

      let system_ip = await publicIp.v4()
      this.$store.commit('SetSystemDetails', { "system_ip" : system_ip })
    },
    async StartMockExam(){
      
      const response = await this.$http({ 
            method: 'post',
            url: `${this.$store.getters.GetServer}/admin/fetch-mock`,
            data : { ...this.$store.getters.GetTempAdmin , ...this.$store.getters.GetSystemDetails , ...this.$store.getters.GetSystemInfo , type : process.env.VUE_APP_BUILD_TYPE },
            onDownloadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length');
                if (totalLength !== null) {
                    // this.Download_Percentage = Math.round( (progressEvent.loaded * 100) / totalLength )
                    console.log(this.Download_Percentage)
                }

            }
        })
          if(response && response['data']){

              this.ValidateAndStart(response.data) ;

          }else{

          }
    },
    ValidateAndStart(info){

      if(!info['exam'] && Object.keys(info['exam']).length <= 0 && !info['exam_info'] && Object.keys(info['exam_info']).length <= 0 && info['student'] && Object.keys(info['student']).length <= 0){
          return
      }
      info['exam']['start_date'] = Moment(info['student']['datetime']).format('YYYY-MM-DD')
      info['exam']['start_time'] = Moment(info['student']['datetime']).format('HH:MM:SS')
      info['student']['exam_state'] = 'start';
      this.$store.commit('SetUserInfo',info['student']);
      this.$store.commit('setExamDetails', info['student']['datetime'])
      let temp = this.$store.getters.GetSystemInfo
      let system = temp && Object.keys(temp).length > 0 ? temp : { 'system_no' : Math.floor(100 + Math.random() * 900) }

      Socket.close();
      let socket = io(`${this.$store.getters.GetServer}/student` , { query: `user_id=${this.GetUserInfo['id']}&first_name=${this.GetUserInfo['first_name']}&system_no=${system['system_no']}`})
      Socket.init(socket)

      this.$store.commit('SetLoginTimeValidation',info['exam_info']['time_validation']);
      this.$store.commit('SetCalculator',info['exam_info']['calculator_enable']);
      this.$store.commit('SetCalculatorType',info['exam_info']['simple_calculator']);
      this.$store.commit('setResumeExam', false);
      this.$Socket.WriteLOGs(`${info['student']['first_name']} Mock User Logged In at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
      Time.calculateExamDuration(info['exam_info'])

      this.$store.dispatch('getExam', info['exam']);
      this.$router.push('/instruction')
      this.$eventHub.$emit("LOADING",'false');

     
    }
  },
  mounted(){

    !process.env.IS_ELECTRON &&  process.env.VUE_APP_BUILD_TYPE == 'Web' ? this.GetIp() : {}

  }

};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

</style>
