import Questions from './Questions';
import Subject   from './Subjects';
import Time      from './Time';
import ActiveQuestion from './ActiveQuestions';

import DatastoreResponse from '../../datastore/Response';
import QuestionControl from '../../utils/QuestionControl';

const state = {
    
    exam_status: false,
    exam_id: 0,
    exam_name: '',
    total_questions: 0,
    total_marks: 0,
    duration: 0,
    instructions: '',
    languages: [],
    essay: [],
    selected_language: null,
    resume_exam: false,
    start_time:null,    
    responseSyncedCount: 0,
    fetch_exam_status : false,
    submit_exam : false

}

const mutations = {
    setExam(state, payload) {

        state.exam_id = parseInt(payload.exam_id)
        state.exam_name = payload.exam_name
        state.instructions = payload.instruction
        state.languages = payload.languages
        state.selected_language = payload.languages[0]
        state.total_questions = parseInt(payload.total_questions)
        state.total_marks = parseInt(payload.total_marks)
        state.essay = payload.essay
        state.duration = parseInt(payload.duration)
        let time = payload.start_time.split(":");
        if(time[0].length == 1){
            let hh = time[0] 
            time[0] = "0"+ hh
        } 
        if(time[1].length == 1){
            time[1] = "00"
        }
        state.start_time = time[0]+":"+time[1];
    },
    setExamFailed(state) {
        state.exam_status = false
    },
    setLanguage(state, payload){
        state.selected_language = payload
    },
    startExam(){
        state.exam_status = true
    },
    setExamInstruction(state, payload){

        state.instructions = payload
    },
    setExamId(state, payload){
        state.exam_id = payload
    },
    setResponseSyncedCount(state, payload){
        state.responseSyncedCount = payload
    },
    setResumeExam(state, payload){
        state.resume_exam = payload
    },
    setFetchExamStatus(state,payload){
        state.fetch_exam_status = payload
    },
    setExamSubmitStatus(state,payload){
        state.submit_exam = payload
    }
}

const actions = {
    getExam({commit}, payload) {

        commit('setExam', payload)
        commit('setSubjects', payload)
        commit('setQuestions', payload)
        commit('setActiveQuestion', Questions.state.subject_wise_questions[Subject.state.selected_subject][0])
        commit('setQuestionDisplayedAt', Time.state.currentTime)
        QuestionControl.manageResponse()
    },
    getUserExamData({commit}){
        DatastoreResponse.get()
            .then(response => {

                commit('setResponseFromStorage', response)
                commit('setAnsweredFromStorage', response)
                commit('setUnAnsweredFromStorage', response)
                commit('setMarkedFromStorage', response)
                commit('setTimeTaken', response)
            }, error => {
                console.log(error)
            })
    }
}

const getters = {
    getInstruction(state) {
        return state.instructions;
    },
    getLanguage(state) {
        return state.languages
    },
    getSelectedLanguage(state) {
        return state.selected_language
    },
    getExamName(state){
        return state.exam_name
    },
    getExamId(state){
        return state.exam_id
    },
    getTotalQuestions(state){
        return state.total_questions
    },
    getExamStatus(state){
        return state.exam_status
    },
    getQuestionEssay(state){

        if(ActiveQuestion.state.essay_id[state.selected_language]) {
            return state.essay[parseInt(ActiveQuestion.state.essay_id[state.selected_language])]
        }

        return "Essay is not available in selected language, please change the language."

    },
    getResponseSyncedCount(state){
        return state.responseSyncedCount
    },
    getResumeExam(state){
         return state.resume_exam
    },
    getSelectedSessionExamTime(state){
        
        return state.start_time
    },
    getFetchExamStatus(state){
        return state.fetch_exam_status
    },
    getSubmitExamStatus(state){
        return state.submit_exam
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
