<template>
    <div class="main-container">
        <v-layout row>
                <v-flex xs5 pa-4 style="margin:auto;margin-top:16%;">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>Student Login</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text v-if="fields.length > 0" style="padding-bottom: 0px;">
                                <v-form>
                                    <v-text-field v-if="First_Step" v-bind:key="fields[0].name" :name="fields[0].name" :label="fields[0].label"
                                                    :type="fields[0].type" v-model="Login_Data[fields[0].name]"></v-text-field>
                                    <v-text-field v-if="Second_Step" v-bind:key="fields[1].name" :name="fields[1].name" :label="fields[1].label"
                                                    :type="fields[1].type" v-model="Login_Data[fields[1].name]"></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn v-if="First_Step" style="padding: 0px 30px;" color="primary" @click="VerifyUser">Verify {{ fields[0].label }}</v-btn>
                                <v-btn v-if="Second_Step" style="padding: 0px 30px;" color="primary" @click="VerifyPassword">Verify {{ fields[1].label }}</v-btn>
                            </v-card-actions>
                        </v-card>
                        <!-- <v-flex>{{ Download_Percentage }}</v-flex> -->
                </v-flex>
                <submit style="display:none;" ref="SubmitExam"></submit>
        </v-layout>
        <v-spacer></v-spacer>
    </div>
</template>

<script>

    import Vue   from 'vue';
    import axios from 'axios';
    import Time  from '../utils/Time';
    import DatastoreResponse from '../datastore/Response';
    import io from 'socket.io-client'
    import Socket from '../socket/Socket';
    import SystemDB from '../datastore/Exams';
    import Submit from './Buttons/Submit.vue'

    export default {
        components:{
            Submit
        },
        data(){
            return {
                Login_Data  : {},
                First_Step  : true,
                Second_Step : false,
                Download_Percentage : 0
            }
        },
        computed: {
            fields(){
                let Login_Data = {}
                let fields = [ {"name" : "first_name" , "label" : "Roll No" , 'type' : "text" } , {"name" : "password" , "label" : "Password" , 'type' : "password" } ]
                for(let i=0; i<fields.length; i++) {
                    Login_Data[fields[i].name] = ''
                }
                this.Login_Data = Login_Data
                return fields
            },
            GetUserInfo(){

                return { ...this.$store.getters.GetUserInfo }
            }
        },
        methods: {
            GetSystem(){

                return SystemDB.get(this.$nedb.system,{})
            },
            VerifyUser() {

                this.$eventHub.$emit("LOADING",'true');
                let data = {}
                data[Object.keys(this.Login_Data)[0]] = Object.values(this.Login_Data)[0];

                if(Object.values(this.Login_Data).filter(x => x).length < 1){
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Enter ${this.fields[0]['label']}`,"Color":"red","Close": false })
                    this.$eventHub.$emit("LOADING",'false');
                    return
                }

                this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Is Trying To Verify . at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);

                this.$http.post(`${this.$store.getters.GetServer}/student/student_login`,data)
                    .then(response =>{
                        
                        if(response && response['data'] && response['data']['response']){
                            
                            this.$store.commit('SetUserInfo',response['data']['response']);
                            this.$store.commit('setExamDetails', response['data']['response']['datetime'])

                            this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Verified Successfully at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);

                            this.First_Step = false;
                            this.Second_Step = true;
                            
                            let temp = this.$store.getters.GetSystemInfo
                            let system = temp && Object.keys(temp).length > 0 ? temp : { 'system_no' : Math.floor(100 + Math.random() * 900) }
                            
                            Socket.close();
                            let socket = io(`${this.$store.getters.GetServer}/student` , { query: `user_id=${this.GetUserInfo['id']}&first_name=${this.GetUserInfo['first_name']}&system_no=${system['system_no']}`})
                            Socket.init(socket);
                            
                        }else if(response && response['data'] && response['data']['error']){

                            this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Is Failed To Login`);
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `${response['data']['error']['message'] ? response['data']['error']['message'] : response['data']['error']['message'] === '' ? `Contact Admin Error Code ${response['data']['error']['err_code']}` : response['data']['error'] }`,"Color":"red","Close": false })

                        }
                        this.$eventHub.$emit("LOADING",'false');
                    
                    })
                    .catch(error =>{

                        this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Is Failed To Login at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                        this.$eventHub.$emit("LOADING",'false');
                        error && error.message === "Network Error" ? this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": 'Please Check Internet Connection .',"Color":"red","Close": false }) : this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `${error}`,"Color":"red","Close": false });  
                        
                    })

            },
            VerifyPassword(){
                
                let user_info = this.GetUserInfo[Object.keys(this.Login_Data)[1]]

                if(Object.values(this.Login_Data).filter(x => x).length < 2 ){

                    this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Failed To Verify Password at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Enter ${this.fields[1]['label']}`,"Color":"red","Close": false })
                    this.$eventHub.$emit("LOADING",'false');
                    return
                }

                if(Object.values(this.Login_Data)[1] == user_info){

                    this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Verified Password Successfully at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                    this.GetNextInformation();

                }else{

                    this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Failed To Verify Password at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Password Incorrect`,"Color":"red","Close": false })
                }

            },
            async GetNextInformation(){

                this.$eventHub.$emit("LOADING",'true');

                let data = { center_id : this.GetUserInfo['center_id'] , first_name : this.GetUserInfo['first_name'], datetime : this.GetUserInfo['datetime'] }
                data['system_details'] = { ...this.$store.getters.GetSystemDetails , ...this.$store.getters.GetSystemInfo , type : process.env.VUE_APP_BUILD_TYPE }
                this.$http.post(`${this.$store.getters.GetServer}/student/exam_info`,data)
                        .then(async (response) =>{

                            if(response && response['data'] && response['data']['response']){

                                this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Successfully Fetched Exam Info at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);

                                this.$store.commit('SetLoginTimeValidation',response['data']['response']['time_validation']);
                                this.$store.commit('SetCalculator',response['data']['response']['calculator_enable']);
                                this.$store.commit('SetCalculatorType',response['data']['response']['simple_calculator']);
                                this.$store.commit('setResumeExam',response['data']['response']['resume_exam']);
                                Time.calculateExamDuration(response['data']['response'])
                                this.ShipExamInformation();
                                await DatastoreResponse.removeAll()
                                let response_data = response['data']['response']['resume_data'].map(({ _id , ...rest }) => rest);
                                await DatastoreResponse.insert(response_data)

                            }else{

                                this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Failed To Fetch Exam Info at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()} .`);
                                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Could Not find The Exam Information`,"Color":"red","Close": false })
                            }

                        })
                        .catch(error => {

                            this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Failed To Fetch Exam Info at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Could Not find The Exam Information ${error}`,"Color":"red","Close": false })

                        })
                
            },
            async ShipExamInformation(){

                const response = await this.$http({ 
                        method: 'post',
                        url: `${this.$store.getters.GetServer}/student/ship_exam`,
                        data : { 'center_id' : this.GetUserInfo['center_id'] , 'first_name' : this.GetUserInfo['first_name'], 'datetime' : this.GetUserInfo['datetime'] , "exam_id" : this.GetUserInfo['exam_id'] ,"set_id" : 1 },
                        onDownloadProgress: (progressEvent) => {
                           
                           const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length');
                            if (totalLength !== null) {
                                this.Download_Percentage = Math.round( (progressEvent.loaded * 100) / totalLength )
                            }

                        }
                    })
                if(response && response['data']){

                    this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Successfully Shipped Exam at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                    const stream = response.data;
                    this.$store.dispatch('getExam', stream);
                    this.GetUserInfo['exam_state'] == 'start' ? this.$router.push('/instruction') : this.Resubmit()
                    
                }else{

                    this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Failed To Ship Exam`);
                }
                this.$eventHub.$emit("LOADING",'false');
            },
            SetVerifiedimage(){

                let User = this.$store.getters.GetUserInfo
                this.$http.post(`${this.$store.getters.GetServer}/student/upload_verified_image`, User )
                    .then(response =>{
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            async Resubmit(){

                this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Submiting at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Your Responses are Saved , You Cannot Login After Exam Time , Submitting The Exam`,"Color":"green","Close": false })
                this.$refs.SubmitExam.finish(true)
            }
        },
        mounted(){

            this.$eventHub.$emit("LOADING",'false');
            
        }
    }

</script>
<style>

.header-contant{
    background-color: #666666;
    padding: 20px;
    width: 100%    
}
.header-contant p{
    color: white;
    margin-bottom: 0px !important;
}
.login-box{
    max-width: 400px;
    height: auto;
    box-shadow: 0px 0px 3px 0px rgb(15, 15, 15,0.4);
    margin: auto;
    background-color: rgb(241, 241, 241);
    border-radius: 10px;
}
.box-header{
    height: 40px;
    background-color: rgb(214, 214, 214);
    padding: 5px 15px;
    overflow: hidden;
}
.main-container{
    width: 100%;
}

</style>
