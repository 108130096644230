<template>
    <v-layout row id="panel">
        <v-flex xs12 sm12 md12 :style="{ 'overflow-y' : 'scroll' , 'overflow-x': 'hidden' , margin: '10px' , height : 'calc( 100vh - 64px)' } ">
            <v-flex style="display:flex;">
                <v-flex>
                    <app-subjects></app-subjects>
                    <app-question-info></app-question-info>
                </v-flex>
            </v-flex>
            <app-question-panel></app-question-panel>
            <app-question-control></app-question-control>
            <app-instruction-dialog></app-instruction-dialog>
            <app-questions-dialog></app-questions-dialog>
            <app-exam-submit-dialog></app-exam-submit-dialog>

            <v-dialog v-model="Relogin_Dialog" persistent scrollable max-width="50%">
            <v-card>
                <v-card-title class="headline">Warning</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <h2 style="padding-top: 12px;">It seems that you're experiencing internet connectivity issues.Please try logging out and connecting to a stable internet network. </h2>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="logOutUser">Logout</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        </v-flex>
    </v-layout>
</template>
<script>

    
    import Promise from 'bluebird';

    let interval = null;
    import appTimer from '../Time/Time.vue'
    import appSubjects from '../subjects/Subjects.vue'
    import appQuestionInfo from './QuestionInfo.vue'
    import appQuestionPanel from './QuestionPanel.vue'
    import appQuestionControl from './QuestionControl.vue'
    import appInstructionDialog from '../instruction/InstructionDialog.vue'
    import appQuestionsDialog from '../question/QuestionsDialog.vue'
    import appExamSubmitDialog from './SubmitExam.vue'
    import DatastoreResponse from '../../datastore/Response'

    export default {
        data(){
            return {
                // Timer : Math.random() * (300000 - 1800000) + 1800000 // Old Code
                // Timer : Math.floor(Math.random() * 60000),
                Failed_Attempts : 0,
                Relogin_Dialog : false,
            }
        },
        components: {
          appTimer,
          appSubjects,
          appQuestionInfo,
          appQuestionPanel,
          appQuestionControl,
          appInstructionDialog,
          appQuestionsDialog,
          appExamSubmitDialog
        },
        computed: {
            height(){
                return (this.$store.getters.getHeight * 85) / 100
            },
            student(){
                return this.$store.getters.GetUserInfo
            }
        },
        destroyed() {
            clearInterval(interval);
        },
        watch: {
            Failed_Attempts: {
                handler(val) {
                    if(val > 2){
                        this.Relogin_Dialog = true;
                    }
                },
                immediate: true
            },
        },
        methods:{
            SaveReponseToDB(){

                DatastoreResponse.get()
                    .then(response =>{

                        let filter_data = response.filter(x => x.updated == 0 )
                        let data = {
                            "responses" : filter_data,
                            "student"   : { "first_name" : this.student['first_name'] },
                         }
                        return filter_data && filter_data.length > 0 ? this.$http.post(`${this.$store.getters.GetServer}/student/save_responses`,data) : null
                    })
                    .then(response =>{

                        if(response){

                            this.$Socket.WriteLOGs(`${this.student['first_name']} Updated Responses at ${new Date().toDateString()} , ${new Date().toLocaleTimeString()} `);
                            let responses = response && response['data'] && response['data']['response'] ? response['data']['response'] : []
                            let info = responses.map(x => { return { 'user_id' : x['user_id'] , "exam_id" : x['exam_id'] , "question_id" : x['question_id'] , "subject_id" : x['subject_id']}  })
                            return Promise.mapSeries(info,(query) => DatastoreResponse.update(query)) 
                        }else{
                                
                            return "Update Not Required"
                        }
                    })
                    .then(response =>{

                        console.log(response);
                        this.Failed_Attempts = 0
                    })
                    .catch(error =>{

                        console.log(error)
                        this.Failed_Attempts = this.Failed_Attempts + 1;

                    })
            },
            logOutUser(){
                this.$Socket.ResetDBShutDown(true)
                window.location.reload();
            }
        },
        created() {
            let _this = this;
            const randomSecond = Math.floor(Math.random() * 60);

            // Calculate the time remaining until the next minute
            const currentTime = new Date();
            const currentSecond = currentTime.getSeconds();
            const remainingSeconds = 60 - currentSecond;

            // Calculate the delay time in milliseconds
            const delayTime = (remainingSeconds + randomSecond) * 1000;
            
            interval = setInterval(() => {
                _this.SaveReponseToDB();
            },delayTime);
        },
    }
</script>
<style scoped>

</style>