<template>

    <v-layout wrap row>
        <v-flex xs12 sm12 md12>
            <h1 style="background-color: #bce8f5; font-size: 20px; font-weight: bold; padding: 10px 35px;">Instructions</h1>
            <div id="instructions"
                 v-html="instruction"></div>
            <h1 style="text-align: center;" v-if="!status">Please Wait Until You Exam Starts Automatically. </h1>
            <div style="text-align: center;padding-top: 5px;">
                <v-btn v-if="status" color="primary" @click.native="StartExam()">Start Exam</v-btn>
            </div>
        </v-flex>        
    </v-layout>
        
</template>

<script>

    export default {
      computed: {
        instruction(){
          return this.$store.getters.getInstruction
        },
        height(){
          return (400 * 85) / 100
        },
        status(){
            return this.$store.getters.getExamStatus
        },
        getUserDetails(){
            return this.$store.getters.GetUserInfo
        }
      },
      watch: {
        status(){

            if(this.status) {
                this.$router.push('/exam');
                this.$Socket.WriteLOGs(`${this.getUserDetails['first_name']} Exam Started at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
            }

        }
      },
      mounted(){
          this.$store.dispatch('getUserExamData');
          this.$Socket.WriteLOGs(`${this.getUserDetails['first_name']} Is On Instruction Page at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
      },
      methods:{
        StartExam(){
            this.$router.push('/exam');
            this.$Socket.WriteLOGs(`${this.getUserDetails['first_name']} Exam Started at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
        }
      }
    }
</script>
<style scoped>
    #instructions {
        padding: 25px 14%;
        height: 100% !important;
        overflow: auto;
        border-bottom: 1px solid lightgray;
    }
</style>