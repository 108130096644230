<template>
    <v-layout row justify-center>
        <v-dialog v-model="questionsDialog" persistent scrollable max-width="50%">
            <v-card>
                <v-card-title class="headline">
                    <v-flex>
                        <span>Questions </span>
                        <v-icon style="float:right;cursor:pointer;" @click.native="questionsDialog = false">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div v-for="(question, i) in questions"
                         class="question" @click="changeQuestion(i+1)" :key="i">

                        <div    v-if="question.locale[language]"
                                v-html="convertText(question.locale[language].question_text)"></div>

                        <div v-else>Question is not available in the selected language, please change the language</div>


                        <div>
                            <v-chip style="margin:2px;" color="primary" text-color="white">{{ i+1 }}</v-chip>
                            <v-chip style="margin:2px;" color="primary" text-color="white">{{ question.question_type | questionType }}</v-chip>
                        </div>

                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    import QuestionControl from '../../utils/QuestionControl'
    import DatastoreResponse from '../../datastore/Response'
    import QuestionTime from '../../utils/Time'
    import Calculate from '../../utils/Calculate'

    export default {
        computed: {
            questions(){
                return this.$store.getters.getSelectedSubjectQuestions
            },
            questionsDialog: {
                get() {
                    return this.$store.getters.getQuestionsDialogStatus
                },
                set(value){
                    this.$store.commit('setQuestionsDialogStatus', value)
                }
            },
            language(){
                return this.$store.getters.getSelectedLanguage
            }
        },
        filters: {
            questionType(type){
                if('fill' === type) {
                    return 'Fill in Blank'
                }

                if('MCQ' === type) {
                    return "Multiple Choice"
                }

                if('PAR' === type) {
                    return "Multiple Choice with Partial Marking"
                }

                if('SC' === type) {
                    return 'Single Choice'
                }

                if('MAT' === type) {
                    return 'Matrix'
                }
            }
        },
        methods: {
            changeQuestion(value){

                console.log(value)

                QuestionTime.saveTimeTaken()
                Calculate.calculate()
                DatastoreResponse.store()
                this.$store.commit('setQuestionsNumber', value)
                QuestionControl.change()
            }
        }
    }
</script>
<style scoped>
    .question{
        margin: 5px; padding: 10px; border: 1px solid #d3d3d3a6; overflow: auto; border-radius: 2px;
    }
    .question:hover {
        cursor: pointer;
    }
</style>